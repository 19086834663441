import fnv1a from './fnv1a';
import { tea } from './tea';

export function teaHash(v0: string | number, v1: number = 0) {
    if (typeof v0 === 'string') {
        v0 = fnv1a(v0) | 0;
    }

    const s = { v0, v1 };
    tea(s);

    return (s.v0 & 0xffffff) / 0x1000000;
}

export function teaRandom(seed: string, state: { n: number }) {
    const teaHashState = { v0: fnv1a(seed), v1: state.n++ };
    tea(teaHashState);
    return (teaHashState.v0 & 0xffffff) / 0x1000000;
}

export function createPrng(seed: string, startAt = 0) {
    const state = { n: startAt };

    return () => teaRandom(seed, state);
}
