import { ReplicantAsyncActionAPI, ReplicantSyncActionAPI } from './api/ReplicantAPI';
import { AsyncGetters } from './ReplicantAsyncGetters';
import { ComputedProperties } from './ReplicantComputedProperties';
import { PartialReplicant, Replicant } from './ReplicantConfig';
import { Messages } from './ReplicantMessages';
import { EmptyRuleset, Ruleset } from './ReplicantRuleset';
import { ScheduledActions } from './ReplicantScheduledActions';
import type { SharedStates } from './ReplicantSharedStates';
import SB from './SchemaBuilder';
import { WithMeta } from './systemStateFields';

export type ActionType = 'sync' | 'async';

export type SyncAction<T extends Replicant, TReturn, TArgs> = {
    fn: (
        state: WithMeta<T['state'], T['ruleset'], T['sharedStates']>,
        args: TArgs,
        api: ReplicantSyncActionAPI<T>,
    ) => TReturn;
    type: 'sync';
};

export type AsyncAction<T extends Replicant, TReturn, TArgs> = {
    fn: (
        state: WithMeta<T['state'], T['ruleset'], T['sharedStates']>,
        args: TArgs,
        api: ReplicantAsyncActionAPI<T>,
    ) => TReturn | Promise<TReturn>;
    type: 'async';
};

// Overloaded action() helper to handle case with actions that take no arguments.
export function action<
    TUserState,
    TRuleset extends Ruleset,
    TMessages extends Messages<TUserState, TRuleset, TSharedStates>,
    TScheduledActions extends ScheduledActions,
    TSharedStates extends SharedStates,
    TReturn,
>(
    fn: (
        state: WithMeta<TUserState, TRuleset, TSharedStates>,
        _: void,
        api: ReplicantSyncActionAPI<
            PartialReplicant<{
                state: TUserState;
                ruleset: TRuleset;
                messages: TMessages;
                scheduledActions: TScheduledActions;
                sharedStates: TSharedStates;
            }>
        >,
    ) => TReturn extends Promise<any> ? never : TReturn, // sync action may not return a promise
): SyncAction<
    PartialReplicant<{
        state: TUserState;
        messages: TMessages;
        ruleset: TRuleset;
        scheduledActions: TScheduledActions;
        sharedStates: TSharedStates;
    }>,
    TReturn,
    void
>;
export function action<
    TUserState,
    TRuleset extends Ruleset,
    TMessages extends Messages<TUserState, TRuleset, TSharedStates>,
    TScheduledActions extends ScheduledActions,
    TSharedStates extends SharedStates,
    TReturn,
    TArgs,
>(
    fn: (
        state: WithMeta<TUserState, TRuleset, TSharedStates>,
        args: TArgs,
        api: ReplicantSyncActionAPI<
            PartialReplicant<{
                state: TUserState;
                messages: TMessages;
                ruleset: TRuleset;
                scheduledActions: TScheduledActions;
                sharedStates: TSharedStates;
            }>
        >,
    ) => TReturn extends Promise<any> ? never : TReturn, // sync action may not return a promise
): SyncAction<
    PartialReplicant<{
        state: TUserState;
        messages: TMessages;
        ruleset: TRuleset;
        scheduledActions: TScheduledActions;
        sharedStates: TSharedStates;
    }>,
    TReturn,
    TArgs
>;
export function action(fn: any) {
    return { fn, type: 'sync' };
}

// Overloaded asyncAction() helper to handle case with actions that take no arguments.
export function asyncAction<
    TUserState,
    TRuleset extends Ruleset,
    TMessages extends Messages<TUserState, TRuleset, TSharedStates>,
    TScheduledActions extends ScheduledActions,
    TComputedProperties extends ComputedProperties,
    TSharedStates extends SharedStates,
    TAsyncGetters extends AsyncGetters<TUserState, TRuleset, TComputedProperties, TSharedStates>,
    TReturn,
>(
    fn: (
        state: WithMeta<TUserState, TRuleset, TSharedStates>,
        _: void,
        api: ReplicantAsyncActionAPI<
            PartialReplicant<{
                state: TUserState;
                ruleset: TRuleset;
                messages: TMessages;
                computedProperties: TComputedProperties;
                scheduledActions: TScheduledActions;
                sharedStates: TSharedStates;
                asyncGetters: TAsyncGetters;
            }>
        >,
    ) => TReturn | Promise<TReturn>,
): AsyncAction<
    PartialReplicant<{
        state: TUserState;
        ruleset: TRuleset;
        messages: TMessages;
        computedProperties: TComputedProperties;
        scheduledActions: TScheduledActions;
        sharedStates: TSharedStates;
        asyncGetters: TAsyncGetters;
    }>,
    TReturn,
    void
>;
export function asyncAction<
    TUserState,
    TRuleset extends Ruleset,
    TMessages extends Messages<TUserState, TRuleset, TSharedStates>,
    TScheduledActions extends ScheduledActions,
    TComputedProperties extends ComputedProperties,
    TSharedStates extends SharedStates,
    TAsyncGetters extends AsyncGetters<TUserState, TRuleset, TComputedProperties, TSharedStates>,
    TReturn,
    TArgs,
>(
    fn: (
        state: WithMeta<TUserState, TRuleset, TSharedStates>,
        args: TArgs,
        api: ReplicantAsyncActionAPI<
            PartialReplicant<{
                state: TUserState;
                ruleset: TRuleset;
                messages: TMessages;
                computedProperties: TComputedProperties;
                scheduledActions: TScheduledActions;
                sharedStates: TSharedStates;
                asyncGetters: TAsyncGetters;
            }>
        >,
    ) => TReturn | Promise<TReturn>,
): AsyncAction<
    PartialReplicant<{
        state: TUserState;
        ruleset: TRuleset;
        messages: TMessages;
        computedProperties: TComputedProperties;
        scheduledActions: TScheduledActions;
        sharedStates: TSharedStates;
        asyncGetters: TAsyncGetters;
    }>,
    TReturn,
    TArgs
>;
export function asyncAction(fn: any) {
    return { fn, type: 'async' };
}

export type Actions<T extends Replicant> = {
    [name: string]: SyncAction<T, any, any> | AsyncAction<T, any, any>;
};

/**
 * A helper method for creating map of Replicant actions. Provides type inference for the `state` and `api` arguments in action implementations.
 *
 * Note that this function is no-op at run-time. It only provides correct compile-time typing info for actions map.
 *
 * @param schemaSchema The Replicant state schema object created with `SchemaBuilder`.
 * @param config A map of configuration options, referring to the other items in Replicant configuration created with `createMessages`, `createAsyncGetters` etc.
 * @returns A function that takes and returns a map of Replicant actions.
 */
export function createActions<
    T extends SB.Schema,
    TRuleset extends Ruleset = EmptyRuleset,
    TMessages extends Messages<SB.ExtractType<T>, TRuleset, TSharedStates> = {},
    TScheduledActions extends ScheduledActions = {},
    TComputedProperties extends ComputedProperties = {},
    TSharedStates extends SharedStates = {},
    TAsyncGetters extends AsyncGetters<SB.ExtractType<T>, TRuleset, TComputedProperties, TSharedStates> = {},
>(
    schemaSchema: T,
    config?: {
        ruleset?: TRuleset;
        messages?: TMessages;
        scheduledActions?: TScheduledActions;
        computedProperties?: TComputedProperties;
        sharedStates?: TSharedStates;
        asyncGetters?: TAsyncGetters;
    },
) {
    return <
        TActions extends Actions<
            PartialReplicant<{
                state: SB.ExtractType<T>;
                ruleset: TRuleset;
                messages: TMessages;
                computedProperties: TComputedProperties;
                scheduledActions: TScheduledActions;
                sharedStates: TSharedStates;
                asyncGetters: TAsyncGetters;
            }>
        >,
    >(
        actionsF: TActions,
    ) => actionsF;
}
