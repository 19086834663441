import type { ReplicantEventHandlerAPI } from './api/ReplicantAPI';
import { AnalyticsPayload } from './common/Analytics';
import { APNRawPayload, FCMProps } from './common/PushNotifications';
import type { AsyncGetters } from './ReplicantAsyncGetters';
import { ComputedProperties } from './ReplicantComputedProperties';
import { PartialReplicant } from './ReplicantConfig';
import { Messages } from './ReplicantMessages';
import { Ruleset } from './ReplicantRuleset';
import { ScheduledActions } from './ReplicantScheduledActions';
import type { SharedStates } from './ReplicantSharedStates';
import SB from './SchemaBuilder';
import type { InstagramWebhookEvent } from './server/webhooks/InstagramWebhookHandler';
import type { MessengerWebhookEvent } from './server/webhooks/MessengerWebhookHandler';
import type { TelegramWebhookEvent } from './server/webhooks/TelegramWebhookHandler';
import { WithMeta } from './systemStateFields';
import { NoAny } from './utils/TypeUtils';
import type { WebPushPayload } from './utils/WebPush';

// Handlers for Chatbot webhooks.
export type SessionEndEventData<TPayload = unknown> = {
    playerId: string;
    messengerId: string;
    contextType?: 'SOLO' | 'THREAD' | 'GROUP';
    contextId?: string;
    score?: number;
    payload?: TPayload;
};

/**
 * A map of chatbot assets where key is the asset name and value is the asset path relative package.json in the game repository.
 *
 * The assets are uploaded to a CDN on the `npx replicant deploy` CLI command. Use the asset name to resolve the uploaded asset URLs using `getAssetPath` and `ClientReplicant.getChatbotAssetUrl`.
 *
 * @example
 * ```ts
 * const chatbotAssets: ChatbotAssets = {
 *     mySimpleAsset: 'path/to/simpleAsset.jpg',
 *     myMultiSizeAsset: {
 *         540: 'path/to/multiSizeAsset-small.png',
 *         720: 'path/to/multiSizeAsset-large.png',
 *     },
 * };
 * ```
 */
export type ChatbotAssets = {
    [assetName: string]: string | { [size: number]: string };
};

export type ChatbotEvent = SessionEndEventData;

export type TemplateRendererAPI<TChatbotAssets extends ChatbotAssets = {}> = {
    getAssetPath: (assetName: keyof TChatbotAssets) => string;
    getUserAssetUrl: (key: string) => string;
    getSenderID: () => string;
    getReceiverID: () => string;
};

type FBChatbotMessageButton =
    | {
          type: 'game_play';
          payload?: string;
          game_metadata?: { player_id?: string; context_id?: string };
      }
    | {
          type: 'web_url';
          url: string;
          messenger_extensions?: boolean;
          fallback_url?: string;
          webview_height_ratio?: 'compact' | 'tall' | 'full';
          webview_share_button?: 'hide';
      };

/** https://developers.facebook.com/docs/messenger-platform/reference/send-api/ */
export type FBChatbotMessage = {
    messaging_type: 'UPDATE';
    message:
        | { text: string }
        | {
              /** See https://developers.facebook.com/docs/messenger-platform/reference/templates/generic#attachment for details. */
              attachment: {
                  type: 'template';
                  payload: {
                      template_type: 'generic';
                      image_aspect_ratio?: 'horizontal' | 'square';
                      elements: {
                          title: string;
                          subtitle?: string;
                          image_url?: string;
                          default_action?: FBChatbotMessageButton;
                          buttons?: (FBChatbotMessageButton & { title: string })[];
                      }[];
                  };
              };
          };
};

/** @see https://core.telegram.org/bots/api#message */
export type TelegramChatbotMessage = {
    [key: string]: unknown;
    text?: string;
};

export type ZoomChatbotMessage = {
    templateId: string;
    templateData: { [variable: string]: unknown };
};

type Renderers<TArgs, TAssets extends ChatbotAssets> = {
    facebook?: (opts: {
        args: TArgs;
        payload: AnalyticsPayload;
        api: TemplateRendererAPI<TAssets>;
    }) => FBChatbotMessage;
    instagram?: (opts: {
        args: TArgs;
        payload: AnalyticsPayload;
        api: TemplateRendererAPI<TAssets>;
    }) => FBChatbotMessage;
    line?: (opts: { args: TArgs; payload: AnalyticsPayload; api: TemplateRendererAPI<TAssets> }) => {
        [key: string]: unknown;
    };
    telegram?: (opts: {
        args: TArgs;
        payload: AnalyticsPayload;
        api: TemplateRendererAPI<TAssets>;
    }) => TelegramChatbotMessage;
    zoom?: (opts: { args: TArgs; payload: AnalyticsPayload; api: TemplateRendererAPI<TAssets> }) => ZoomChatbotMessage;
    ios?: (opts: { args: TArgs; payload: AnalyticsPayload; api: TemplateRendererAPI<TAssets> }) => APNRawPayload;
    sms?: (opts: { args: TArgs; payload: AnalyticsPayload; api: TemplateRendererAPI<TAssets> }) => string;
    webpush?: (opts: { args: TArgs; payload: AnalyticsPayload; api: TemplateRendererAPI<TAssets> }) => WebPushPayload;
    android?: (opts: { args: TArgs; payload: AnalyticsPayload; api: TemplateRendererAPI<TAssets> }) => {
        notification: FCMProps;
        payload: AnalyticsPayload;
    };
};
const rendererTypes: (keyof Renderers<any, any>)[] = [
    'android',
    'facebook',
    'instagram',
    'ios',
    'line',
    'telegram',
    'zoom',
    'sms',
    'webpush',
];

export function renderTemplate<TArgs extends SB.Schema, TUploadedAssets extends ChatbotAssets>(opts: {
    args: TArgs;
    renderers: Renderers<NoAny<SB.ExtractType<TArgs>>, TUploadedAssets>;
}) {
    if (!rendererTypes.some((renderer) => opts.renderers[renderer])) {
        throw Error('renderTemplate must define at least 1 renderer among ' + rendererTypes.join(', '));
    }

    return {
        args: opts.args,
        renderers: opts.renderers,
    };
}

export function renderTemplatesWithAssets<
    TChatbotAssets extends ChatbotAssets,
    TChatbotMessageTemplates extends RenderTemplates<TChatbotAssets>,
>(
    assets: TChatbotAssets,
    templates: TChatbotMessageTemplates,
): { assets: TChatbotAssets } & RenderTemplatesWithName<TChatbotMessageTemplates> {
    return { assets, ...renderTemplates(templates) };
}

export type RenderTemplatesWithName<T extends RenderTemplates> = {
    [K in keyof T]: (opts: {
        analyticsUserProperties?: { [key: string]: unknown };
        args: SB.ExtractType<T[K]['args']>;
        payload: AnalyticsPayload;
    }) => ChatbotMessageInstance<SB.ExtractType<T[K]['args']>> & { name: K };
};

export function renderTemplates<TChatbotMessageTemplates extends RenderTemplates>(
    originalTemplates: TChatbotMessageTemplates,
): RenderTemplatesWithName<TChatbotMessageTemplates> {
    const templates = {} as RenderTemplatesWithName<TChatbotMessageTemplates>;

    for (const name in originalTemplates) {
        templates[name] = (opts) => ({
            analyticsUserProperties: opts.analyticsUserProperties,
            args: opts.args,
            name,
            payload: opts.payload,
            renderers: originalTemplates[name]!.renderers,
        });
    }

    return templates;
}

type RenderTemplates<TChatbotAssets extends ChatbotAssets = any> = {
    [name: string]: {
        args: SB.Schema;
        renderers: Renderers<any, TChatbotAssets>;
    };
};

export type ChatbotMessageInstance<TArgs = any, TChatbotAssets extends ChatbotAssets = any> = {
    name: string;
    args: TArgs;
    payload: AnalyticsPayload;
    renderers: Renderers<TArgs, TChatbotAssets>;
    analyticsUserProperties?: { [key: string]: unknown };
};

/** @see https://developers.line.biz/en/reference/messaging-api/#webhook-event-objects */
export type LineWebhookEventType =
    | 'accountLink'
    | 'beacon'
    | 'follow'
    | 'join'
    | 'leave'
    | 'memberJoined'
    | 'memberLeft'
    | 'message'
    | 'postback'
    | 'things'
    | 'unfollow'
    | 'unsend'
    | 'videoPlayComplete';

/** @see https://developers.line.biz/en/reference/messaging-api/#webhook-event-objects */
export type LineWebhookEvent = {
    [key: string]: unknown;
    source:
        | { type: 'user'; userId: string }
        | { type: 'group'; groupId: string; userId: string }
        | { type: 'room'; roomId: string; userId: string };
    /** Time of the event in milliseconds, as set by LINE. */
    timestamp: number;
    type: LineWebhookEventType;
    deliveryContext: {
        isRedelivery: boolean;
    };
};

/** @see https://marketplace.zoom.us/docs/api-reference/app/events */
export type ZoomWebhookEvent = {
    event: 'app_deauthorized';
    payload: {
        account_id: string;
        user_id: string;
        signature: string;
        /** ISO datetime string. */
        deauthorization_time: string;
        client_id: string;
    };
};

export type WebhookEvent =
    | LineWebhookEvent
    | ZoomWebhookEvent
    | InstagramWebhookEvent
    | MessengerWebhookEvent
    | TelegramWebhookEvent;

export type ChatbotEvents<
    TState,
    TMessages extends Messages<TState, TRuleset, TSharedStates>,
    TScheduledActions extends ScheduledActions<TState>,
    TComputedProperties extends ComputedProperties,
    TRuleset extends Ruleset,
    TSharedStates extends SharedStates,
    TAsyncGetters extends AsyncGetters<TState, TRuleset, TComputedProperties, TSharedStates>,
> = {
    /**
     * List of [LINE webhook event types](https://developers.line.biz/en/reference/messaging-api/#webhook-event-objects) to listen to.
     *
     * This controls which LINE webhook events trigger the `onWebhook` event handler. If `onWebhook` is defined the list must include at least one item.
     *
     * Only enabled on the LINE platform.
     *
     * @defaultValue `[]`
     */
    enabledWebhookEvents?: LineWebhookEventType[];

    /**
     * An event handler invoked when the player leaves the game.
     *
     * Requires setting up the GCInstant Replicant extensions with `configureExtensions` from `@play-co/gcinstant/replicantExtensions`.
     * See [the documentation](https://docs.dev.gc-internal.net/gcinstant/replicantExtensions/#usage) for details.
     *
     * The handler is also invoked with a 1-minute timeout in the following edge cases:
     * - Player idles in a Facebook context switch UI dialog.
     * - Player leaves the game during an unresolved context switch dialog.
     *
     * The 1-minute timeout is adjustable with `opts.onGameEndTimeoutMinutes` in `createChatbotConfig`.
     *
     * Only enabled on the Facebook platform for players who have subscribed to the game chatbot with GCInstant.
     *
     * @param isGameFirstInSession `true` if the handler was invoked for the first time in the current session.
     * Due to limitations in Facebook game end detection the handler may be invoked more than once per session.
     */
    onGameEnd?: (
        state: WithMeta<TState, TRuleset, TSharedStates>,
        eventData: SessionEndEventData,
        api: ReplicantEventHandlerAPI<
            PartialReplicant<{
                ruleset: TRuleset;
                state: TState;
                messages: TMessages;
                scheduledActions: TScheduledActions;
                computedProperties: TComputedProperties;
                asyncGetters: TAsyncGetters;
            }>
        >,
        isGameFirstInSession: boolean,
    ) => void | Promise<void>;

    /**
     * An event handler invoked when the player receives a platform webhook event.
     *
     * Creates a Replicant state for the event receiver if one does not exist already. `onLoginAction` is not executed on new state creation here.
     *
     * Requires setting up platform webhook integration: see the [Platform Guides](https://docs.dev.gc-internal.net/replicant/index.html) for details.
     *
     * Note the following differences between the platforms:
     *
     * - LINE:
     *   - Players must subscribe to the game's Official Account in the LINE app in order to interact with the chatbot (see [`GCInstant.subscribeBotAsync`](https://docs.dev.gc-internal.net/gcinstant/generated/classes/Platform.html#subscribeBotAsync)).
     *   - You must enable at least one webhook event type in `createChatbotEvents(stateSchema)({ enabledWebhookEvents: [ ... ]})`.
     * - Zoom:
     *   - `enabledWebhookEvents` is not used: the handler currently only receives [`app_deauthorized` events](https://marketplace.zoom.us/docs/guides/auth/deauthorization/#deauthorization-event-notifications).
     * - Instagram:
     *   - `enabledWebhookEvents` is not used: the handler receives comment, message, referral, postback and reaction events.
     *   - **Important:** to avoid infinite message loops, do not reply to [`is_echo` message events](https://developers.facebook.com/docs/messenger-platform/instagram/features/webhook#messages).
     * - Messenger:
     *   - `enabledWebhookEvents` is not used: the handler receives delivery, feed, message, postback, read and referral events events.
     *   - **Important:** to avoid infinite message loops, do not reply to [`delivery`](https://developers.facebook.com/docs/messenger-platform/reference/webhook-events/message-deliveries), [`is_echo`](https://developers.facebook.com/docs/messenger-platform/reference/webhook-events/message-echoes) or [`read`](https://developers.facebook.com/docs/messenger-platform/reference/webhook-events/message-reads) events.
     * - Telegram:
     *   - `enabledWebhookEvents` is not used: the handler receives all events with a message sender property.
     */
    onWebhook?: (
        state: WithMeta<TState, TRuleset, TSharedStates>,
        webhookEvent: WebhookEvent,
        api: ReplicantEventHandlerAPI<
            PartialReplicant<{
                ruleset: TRuleset;
                state: TState;
                messages: TMessages;
                scheduledActions: TScheduledActions;
                computedProperties: TComputedProperties;
                asyncGetters: TAsyncGetters;
            }>
        >,
    ) => Promise<void>;
};

export type ChatbotConfig<
    TState,
    TMessages extends Messages<TState, TRuleset, TSharedStates>,
    TScheduledActions extends ScheduledActions<TState>,
    TComputedProperties extends ComputedProperties,
    TChatbotAssets extends ChatbotAssets,
    TRuleset extends Ruleset,
    TSharedStates extends SharedStates,
    TAsyncGetters extends AsyncGetters<TState, TRuleset, TComputedProperties, TSharedStates>,
> = {
    events: ChatbotEvents<
        TState,
        TMessages,
        TScheduledActions,
        TComputedProperties,
        TRuleset,
        TSharedStates,
        TAsyncGetters
    >;
    assets: TChatbotAssets | {};
    opts: ChatbotOpts;
};

export function createChatbotEvents<
    TStateSchema,
    TMessages extends Messages<SB.ExtractType<TStateSchema>, TRuleset, TSharedStates>,
    TScheduledActions extends ScheduledActions<SB.ExtractType<TStateSchema>>,
    TComputedProperties extends ComputedProperties,
    TRuleset extends Ruleset,
    TSharedStates extends SharedStates,
    TAsyncGetters extends AsyncGetters<SB.ExtractType<TStateSchema>, TRuleset, TComputedProperties, TSharedStates>,
>(
    stateSchema: TStateSchema,
    _?: {
        computedProperties?: TComputedProperties;
        messages?: TMessages;
        ruleset?: TRuleset;
        scheduledActions?: TScheduledActions;
        sharedStates?: TSharedStates;
        asyncGetters?: TAsyncGetters;
    },
) {
    return (
        events: ChatbotEvents<
            SB.ExtractType<TStateSchema>,
            TMessages,
            TScheduledActions,
            TComputedProperties,
            TRuleset,
            TSharedStates,
            TAsyncGetters
        >,
    ) => events;
}

export type ChatbotOpts = {
    /**
     * Max number of days elapsed from the receiver's last session.
     *
     * If the receiver has exceeded the limit Replicant does not attempt to
     * send any further messages until the receiver starts a new session.
     *
     * Only applied on the Facebook platform.
     *
     * @defaultValue `10`
     */
    maxDaysSinceLastSession: number;

    /**
     * Max number of chatbot messages a user can receive per session.
     *
     * If the receiver has exceeded the limit Replicant does not attempt to
     * send any further messages until the receiver starts a new session.
     *
     * Only applied on the Facebook platform.
     *
     * @defaultValue `5`
     */
    maxMessagesPerSession: number;

    /**
     * See the `onGameEnd` chatbot event. Accurate to 1 minute so only integer values are supported.
     *
     * @defaultValue `1`
     */
    onGameEndTimeoutMinutes: number;
};

/**
 * Create a chatbot configuration object, to be passed into `createConfig({ chatbot, ... })`.
 *
 * @param messages Chatbot messages. Use the `renderTemplatesWithAssets` helper to create the messages object.
 * @param events Chatbot events. Use the `createChatbotEvents` helper to create the events object.
 * @param opts Optional chatbot configuration options.
 * @returns Chatbot configuration object.
 */
export function createChatbotConfig<
    TState,
    TMessages extends Messages<TState, TRuleset, TSharedStates>,
    TScheduledActions extends ScheduledActions<TState>,
    TComputedProperties extends ComputedProperties,
    TChatbotAssets extends ChatbotAssets,
    TRuleset extends Ruleset,
    TSharedStates extends SharedStates,
    TAsyncGetters extends AsyncGetters<TState, TRuleset, TComputedProperties, TSharedStates>,
>(
    messages: { assets?: TChatbotAssets },
    events: ChatbotEvents<
        TState,
        TMessages,
        TScheduledActions,
        TComputedProperties,
        TRuleset,
        TSharedStates,
        TAsyncGetters
    >,
    opts?: Partial<ChatbotOpts>,
): ChatbotConfig<
    TState,
    TMessages,
    TScheduledActions,
    TComputedProperties,
    TChatbotAssets,
    TRuleset,
    TSharedStates,
    TAsyncGetters
> {
    return {
        assets: messages.assets || {},
        events,
        opts: {
            maxDaysSinceLastSession: opts?.maxDaysSinceLastSession ?? 10,
            maxMessagesPerSession: opts?.maxMessagesPerSession ?? 5,
            onGameEndTimeoutMinutes: opts?.onGameEndTimeoutMinutes ?? 1,
        },
    };
}
